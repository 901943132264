import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    profile: null,
    messages: [],
    drawer: true,
    snackbar: {
      visible: false,
      text: ''
    },
    loading: false,
    ctrl: false,
    meta: false,
    title: '',
    visibletypes: []
  },
  mutations: {
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    OPEN_SNACKBAR (state, text) {
      state.snackbar.text = text
      state.snackbar.visible = true
    },
    CLOSE_SNACKBAR (state) {
      state.snackbar.visible = false
    },
    SET_PROFILE (state, profile) {
      Vue.set(state, 'profile', profile)
    },
    SET_LOADING (state, loading) {
      state.loading = loading
    },
    CTRL_PRESSED (state, pressed) {
      state.ctrl = pressed
    },
    META_PRESSED (state, pressed) {
      state.meta = pressed
    },
    SET_TITLE (state, title) {
      state.title = title
    },
    SET_VISIBLE (state, visible) {
      state.visibletypes = visible
      Vue.$cookies.set('visible', JSON.stringify(visible), '3650d')
    }
  },
  actions: {
  },
  modules: {
  },
  getters: {
    loading: (state) => state.loading,
    profile: (state) => state.profile || {},
    getTitle: (state) => state.title || '',
    isLogin: (state) => !!state.profile?.token,
    isMaster: (state) => acl(state, 'master', null),
    isClub: (state) => (id, gender) => acl(state, 'club', id, gender),
    isTeam: (state) => (id, gender) => acl(state, 'team', id, gender),
    isEdv: (state) => (id, gender) => acl(state, 'edv', id, gender),
    isStbM: (state) => (id) => acl(state, 'stbm', id),
    isStbMLiga: (state) => (id) => acl(state, 'stbmliga', id),
    isStbMWk: (state) => (id) => acl(state, 'stbmwk', id),
    isStbMWkDiscipline: (state) => (id, did) => {
      const acl = state.profile?.acl.filter(acl => acl.type === 'stbmwkdiscipline' && (id === null || acl.id === id))
      return !did ? acl.length > 0 : !!acl.find(a => a._discipline === did)
    },
    isStbWWk: (state) => (id) => acl(state, 'stbwwk', id),
    visibletypes: (state) => state.visibletypes,
    isVisible: (state) => (type) => !!state.visibletypes.find(t => t === type)
  }
})

const acl = (state, type, id, gender) => {
  const acl = state.profile?.acl.find(acl => acl.type === type && (id === null || acl.id === id) && (!gender || acl.gender === gender))
  return !!acl
}
