<template>
  <div>
    <v-edit-dialog
      :return-value.sync="wert"
      large
      :save-text="savebutton"
      :cancel-text="cancelbutton"
      @open="open"
      @save="save"
      @close="close"
    >
      <div style="min-width:40px;">
        <v-text-field
          :value="value"
          :label="label"
          readonly
        >
          <template
            v-if="clearable && value"
            #append
          >
            <v-btn
              fab
              x-small
              text
              @click.stop="$emit('clear', id)"
            >
              <v-icon>far fa-trash-alt</v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </div>
      <template #input>
        <v-autocomplete
          v-model="wert"
          :loading="loading"
          :items="items"
          :search-input.sync="search"
          label="Halle suchen"
          hide-no-data
          hint="Mindestens 2 Buchstaben des Ortes eingeben..."
          persistent-hint
          @select="save"
        />
      </template>
    </v-edit-dialog>
    <base-material-dialog
      :value="neu.open"
      icon="far fa-warehouse"
      title="neue Halle anlegen"
      color="primary"
      :actions="[ 'save', 'cancel' ]"
      @close="closenew"
      @save="savenew"
    >
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="neu.name"
              label="Hallenname"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <v-text-field
              v-model="neu.streetAddress"
              label="Straße"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="2"
          >
            <v-text-field
              v-model="neu.streetNumber"
              label="Hausnummer"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="2"
          >
            <v-text-field
              v-model="neu.postalCode"
              label="PLZ"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <v-text-field
              v-model="neu.addressLocality"
              label="Ort"
            />
          </v-col>
        </v-row>
    </base-material-dialog>
  </div>
</template>

<script>
import { useGraphQL } from '@/plugins/graphql'
import gql from 'graphql-tag'

export default {
  name: 'EditDialogHallensuche',

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  props: {
    value: {
      type: String,
      required: false
    },
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    savebutton: {
      type: String,
      required: false,
      default: 'Übernehmen'
    },
    cancelbutton: {
      type: String,
      required: false,
      default: 'Abbrechen'
    },
    clearable: {
      type: Boolean,
      default: false
    },
    allownew: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    wert: null,
    tmpid: null,
    loading: false,
    items: [],
    search: '',
    neu: {
      id: null,
      open: false,
      name: '',
      streetAddress: '',
      streetNumber: '',
      postalCode: '',
      addressLocality: ''
    }
  }),

  computed: {
  },

  methods: {
    open () {
      this.tmpid = this.id
      this.wert = null
    },
    close () {
      this.wert = null
      this.tmpid = null
    },
    save () {
      if (this.wert === -1) {
        this.opennew()
        return
      }

      this.$emit('input', { value: this.wert, id: this.tmpid })
      this.close()
    },
    dosearch (val) {
      this.loading = true

      this.query({
        query: gql`query($ort: String!) {
          VenueFind(addressLocality: $ort, limit: 100) {
            _id
            name
            address {
              streetAddress streetNumber postalCode addressLocality
            }
          }
        }`,
        variables: {
          ort: val
        }
      }).then((result) => {
        this.items = [...result.VenueFind.map(v => ({
          value: v._id,
          plz: v.address.postalCode,
          text: `${v.address.postalCode} -> ${v.name} (${v.address.streetAddress} ${v.address.streetNumber}, ${v.address.postalCode} ${v.address.addressLocality})`
        })).sort((a, b) => a.plz < b.plz ? -1 : 1), ...(this.allownew ? [{ value: -1, text: `Neue Halle anlegen: ${val}` }] : [])]

        this.loading = false
      })
    },
    opennew () {
      this.neu.id = this.tmpid
      this.neu.name = ''
      this.neu.streetAddress = ''
      this.neu.streetNumber = ''
      this.neu.postalCode = ''
      this.neu.addressLocality = ''
      this.neu.open = true
    },
    closenew () {
      this.neu.open = false
    },
    savenew () {
      this.mutate({
        mutation: gql`
          mutation($name: String!, $address: IPostalAddress!) {
            VenueCreate(name: $name, address: $address) { _id }
          }
        `,
        variables: {
          name: this.neu.name,
          address: {
            streetAddress: this.neu.streetAddress,
            streetNumber: this.neu.streetNumber,
            postalCode: this.neu.postalCode,
            addressLocality: this.neu.addressLocality
          }
        }
      }).then((val) => {
        this.closenew()
        this.close()

        this.$emit('input', { value: val.VenueCreate?._id, id: this.neu.id })
      })
    }
  },

  watch: {
    search (val) {
      if (val && val.length >= 2) this.dosearch(val)
      else this.items = []
    }
  }
}
</script>

<style>
.v-small-dialog__activator__content {
  display: block;
}

</style>
