<template>
  <v-textarea
    v-if="disabled && textarea"
    :value="value"
    :label="label"
    disabled
  />
  <v-text-field
    v-else-if="disabled && textfield"
    :value="value"
    :label="label"
    disabled
  />
  <v-edit-dialog
    v-else
    :return-value.sync="wert"
    large
    persistent
    :save-text="savebutton"
    :cancel-text="cancelbutton"
    @open="open"
    @save="save"
    @close="close"
  >
    <div style="min-width:40px;">
      <slot v-if="textarea">
        <v-textarea
          :value="value"
          :label="label"
          readonly
        />
      </slot>
      <slot v-else-if="textfield">
        <v-text-field
          :value="value"
          :label="label"
        />
      </slot>
      <slot v-else>
        {{ value }}&nbsp;
      </slot>
    </div>
    <template #input>
      <v-text-field
        v-if="!textarea"
        v-model="wert"
        :label="label"
        autofocus
      />
      <v-textarea
        v-else
        v-model="wert"
        :label="label"
        autofocus
      />
    </template>
  </v-edit-dialog>
</template>

<script>
export default {
  name: 'EditDialog',

  props: {
    value: {
      type: [String, Number],
      required: false,
      default: ''
    },
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    savebutton: {
      type: String,
      required: false,
      default: 'Übernehmen'
    },
    cancelbutton: {
      type: String,
      required: false,
      default: 'Abbrechen'
    },
    textarea: {
      type: Boolean,
      default: false
    },
    textfield: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    wert: null,
    tmpid: null
  }),

  methods: {
    open () {
      this.tmpid = this.id

      if (this.value === null || this.value === undefined) {
        this.wert = ''
      } else {
        this.wert = `${`${this.value}`.replace(/^ */, '').replace(/ *$/, '')}`
      }
    },
    close () {
      this.wert = null
      this.tmpid = null
    },
    save () {
      this.$emit('input', { value: this.wert, id: this.tmpid })
      this.close()
    }
  }
}
</script>

<style>
.v-small-dialog__activator__content {
  display: block;
}

</style>
