<template>
  <v-edit-dialog
    :return-value.sync="wert"
    large
    persistent
    :save-text="savebutton"
    cancel-text="Abbrechen"
    @open="open"
    @save="save"
    @close="close"
  >
    <div>{{ text }}</div>
    <template #input>
      <v-select
        v-model="wert"
        :label="label"
        :items="myitems"
        autofocus
        :clearable="clearable"
      />
    </template>
  </v-edit-dialog>
</template>

<script>
export default {
  name: 'EditDialogSelect',

  props: {
    value: {
      type: [String, Boolean],
      required: false,
      default: undefined
    },
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    savebutton: {
      type: String,
      required: false,
      default: 'Übernehmen'
    },
    items: {
      type: undefined,
      required: true
    },
    clearable: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  data: () => ({
    wert: null,
    tmpid: null
  }),

  computed: {
    text () {
      return this.myitems?.find(e => e.value === this.value)?.text
    },
    myitems () {
      if (Array.isArray(this.items)) return this.items
      return []
    }
  },

  methods: {
    open () {
      this.tmpid = this.id
      this.wert = this.value
    },
    close () {
      this.wert = null
      this.tmpid = null
    },
    save () {
      this.$emit('input', { value: this.wert, id: this.tmpid })
      this.close()
    }
  }
}
</script>

<style scoped>

</style>
