import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store'

import AdminRouter from '../views/components/admin/plugins/router'
import { graphQL } from '@/plugins/graphql'
import gql from 'graphql-tag'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Index'),
    children: [
      {
        path: '',
        name: 'Startseite',
        component: () => import('../views/components/start')
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('../views/Login')
      },
      {
        path: 'impress',
        name: 'Impressum',
        component: () => import('../views/Impress')
      },
      {
        path: 'reset/:code?/:email64?',
        name: 'Passwort zurücksetzen',
        component: () => import('../views/Reset'),
        props: true
      }
    ]
  },
  ...AdminRouter,
  {
    path: '/:path/:id/:p1?/:p2?/:p3?/:p4?/:p5?/:p6?/:p7?/:p8?/:p9?/:p10?',
    name: 'EventRouter',
    props: true,
    component: () => import('../views/Index'),
    children: [
      {
        path: '/',
        props: true,
        component: () => import('../views/components/EventRouter')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

router.beforeEach(async (to, from, next) => {
  graphQL.mutate({
    mutation: gql`mutation($view: String!) { ClientViewUpdate(view: $view) { _id } }`,
    variables: {
      view: to.path
    }
  })
  if (to.matched.some(record => record.meta.auth)) {
    if (!store.getters.isLogin) {
      next({
        path: '/login',
        query: { origin: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next()
  }
})
export default router
