export default [
  {
    path: '/master',
    component: () => import('@/views/Index'),
    children: [
      {
        name: 'Admin > Wettkampforte',
        path: 'venues',
        meta: {
          auth: true
        },
        component: () => import('../content/venues')
      },
      {
        name: 'Admin > Disziplinen',
        path: 'disciplines',
        meta: {
          auth: true
        },
        component: () => import('../content/disciplines')
      },
      {
        name: 'Admin > Vereine',
        path: 'clubs',
        meta: {
          auth: true
        },
        component: () => import('../content/clubs')
      },
      {
        name: 'Admin > Personen',
        path: 'person',
        meta: {
          auth: true
        },
        component: () => import('../content/person')
      },
      {
        name: 'Admin > Personen zusammenlegen',
        path: 'merge',
        meta: {
          auth: true
        },
        component: () => import('../content/merge.vue')
      },
      {
        name: 'Admin > Hauptevents',
        path: 'events',
        meta: {
          auth: true
        },
        component: () => import('../content/events')
      },
      {
        name: 'Admin > Serverübersicht',
        path: 'server',
        meta: {
          auth: true
        },
        component: () => import('../content/server')
      },
      {
        name: 'Admin > Seitenstatistik',
        path: 'pagelog',
        meta: {
          auth: true
        },
        component: () => import('../content/pagelog.vue')
      }
    ]
  }
]
