import Vue from 'vue'
import router from './router'

const openlink = {
  install (Vue, router) {
    Vue.prototype.$openlink = (link, event) => {
      if (event.metaKey || event.ctrlKey) {
        const routeData = router.resolve({ path: link })
        window.open(routeData.href, '_blank')
      } else {
        router.push(link)
      }
    }
  }
}

Vue.use(openlink, router)
