export const TPsport = [
  { id: '415167291f9b4931aab76622974ccb96', value: 'Gerätturnen Liga (außer DTL)' },
  { id: 'b9b8c11289ca471faec29fa6907ce4f8', value: 'Gerätturnen Einzel' },
  { id: 'bb65ae6785c248709b91247f6d896a1b', value: 'Gerätturnen Mannschaft' }
]

export const EmailType = [
  { id: 'private', value: 'privat' },
  { id: 'business', value: 'geschäftlich' },
  { id: 'other', value: 'andere' }
]

export const PhoneType = [
  { id: 'private', value: 'privat' },
  { id: 'business', value: 'geschäftlich' },
  { id: 'mobile', value: 'mobil' },
  { id: 'other', value: 'andere' }
]

export const AclType = [
  { id: 'master', value: 'Hauptadministrator' },
  { id: 'event', value: 'Event-Administrator' },
  { id: 'club', value: 'Vereinsverantwortlicher' },
  { id: 'team', value: 'Mannschaftsverantwortlicher' },
  { id: 'edv', value: 'EDV-Verantwortlicher' },
  { id: 'stbm', value: 'STB-männlich-Hauptverantwortlicher' },
  { id: 'stbmliga', value: 'STB-männlich-Ligaverantwortlicher' },
  { id: 'stbmwk', value: 'STB-männlich-Wettkampfverantwortlicher' },
  { id: 'stbmwkdiscipline', value: 'STB-männlich-Geräteeingabe' },
  { id: 'stbw', value: 'STB-weiblich-Hauptverantwortlicher' },
  { id: 'stbwliga', value: 'STB-weiblich-Ligaverantwortlicher' },
  { id: 'stbwwk', value: 'STB-weiblich-Wettkampfverantwortlicher' },
  { id: 'stbwwkdiscipline', value: 'STB-weiblich-Geräteeingabe' }
]

export const AddressType = [
  { id: 'private', value: 'privat' },
  { id: 'business', value: 'geschäftlich' },
  { id: 'other', value: 'andere' }
]

export const StbM2021WKMode = [
  { value: 'lp_3aus4', text: 'Ligapokal 3 aus 4' },
  { value: 'wk_score4', text: 'Score 4 Turner' },
  { value: 'wk_4aus5', text: '2er Begegnung 4 aus 5' },
  { value: 'lf_4aus5', text: 'Ligafinale 4 aus 5' },
  { value: 'lf_4aus4', text: 'Ligafinale 4 aus 4' },
  { value: 'test', text: 'Testwettkämpfe 4 aus 10' }
]

export const EventStatus = [
  { value: 'EventScheduled', text: 'Event leer', wk: true },
  { value: 'EventPostponed', text: 'Event verschoben, Termin offen', wk: false },
  { value: 'EventRescheduled', text: 'Event verschoben, Termin steht fest', wk: false },
  { value: 'EventMovedOnline', text: 'Event in Vorbereitung', wk: true },
  { value: 'EventStarted', text: 'Event begonnen', wk: true },
  { value: 'EventFinished', text: 'Event beendet', wk: true },
  { value: 'EventConfirmed', text: 'Event bestätigt', wk: false },
  { value: 'EventCancelled', text: 'Event abgesagt', wk: false },
  { value: 'EventJudged', text: 'Event durch Schiedsgericht gewertet', wk: false }
]

export const EventType = [
  { value: 'stb_m_2021', text: 'STB Liga männlich (v2021)', domains: ['localhost', '127.0.0.1', '192.168.0.190', 'ergebnisdienst.stb.de', 'kutu.ergebnisdienst.stb.de'] },
  { value: 'stb_w_2023', text: 'STB Liga weiblich (v2023)', domains: ['localhost', '127.0.0.1', '192.168.0.190', 'ergebnisdienst.stb.de', 'kuti.ergebnisdienst.stb.de'] },
  { value: 'stb_ltf_2024', text: 'Landesturnfest (v2024)', domains: ['localhost', '127.0.0.1', '192.168.0.190', 'ergebnisdienst.stb.de', 'ltf.ergebnisdienst.stb.de'] }
]

export const StbW2023WkMode = [
  { value: 'wk_4aus5', text: 'Wettkampf 4 aus 5' },
  { value: 'wk_3aus5', text: 'Wettkampf 3 aus 5' }
]

export const StbLtf2024TcMode = [
  { value: 'lk1', text: 'LK 1' },
  { value: 'lk2', text: 'LK 2' },
  { value: 'lk3', text: 'LK 3' }
]

export const GenderType = [
  { value: 'm', text: 'männlich' },
  { value: 'w', text: 'weiblich' },
  { value: 'd', text: 'divers' },
  { value: 'mixed', text: 'mixed' }
]
