<template>
  <v-edit-dialog
    :return-value.sync="wert"
    large
    persistent
    :save-text="savebutton"
    cancel-text="Abbrechen"
    @open="open"
    @save="save"
    @close="close"
  >
    <div>
      <slot>
        {{ value | dateformat(dateformat) }}
      </slot>
    </div>
    <template #input>
      <date-time-selector
        v-model="wert"
        :label="label"
        :clearable="clearable"
      />
    </template>
  </v-edit-dialog>
</template>

<script>
export default {
  name: 'EditDialog',

  props: {
    value: {
      type: String,
      required: false,
      default: undefined
    },
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    savebutton: {
      type: String,
      required: false,
      default: 'Übernehmen'
    },
    dateformat: {
      type: String,
      required: false,
      default: 'DD.MM.YYYY HH:mm'
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: () => ({
    wert: null,
    tmpid: null
  }),

  methods: {
    open () {
      this.tmpid = this.id
      this.wert = this.value
    },
    close () {
      this.wert = null
      this.tmpid = null
    },
    save () {
      this.$emit('input', { value: this.wert, id: this.tmpid })
      this.close()
    }
  }
}
</script>

<style scoped>

</style>
