import Vue from 'vue'
import { v4 as uuid } from 'uuid'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import ApolloClient from 'apollo-client'
import VueApollo from 'vue-apollo'
import { defaultDataIdFromObject, InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import * as Cookie from 'js-cookie'
import { ref } from '@vue/composition-api'
import gql from 'graphql-tag'
import { GRAPHQLserver as server } from '@/env'
import { root } from '@/main'

import introspectionQueryResultData from '@/fragmentTypes.json'

Vue.use(VueApollo)

export const clientId = uuid()

const client = new SubscriptionClient(server, {
  reconnect: true,
  connectionParams: () => ({
    clientId,
    token: Cookie.get('token')
  })
})

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
})

export const graphQL = new ApolloClient({
  // @ts-ignore
  link: client,
  cache: new InMemoryCache({
    fragmentMatcher,
    dataIdFromObject: object => {
      switch (object.__typename) {
        case 'Identifier': return false
        case 'StbM2021WkDiscipline': return false
        case 'StbM2021LeagueTeamAcl': return false
        case 'StbM2021WkTeamAthleteResult': return false
        case 'StbLtf2024TcTeamAthleteResult': return false
        default: return defaultDataIdFromObject(object)
      }
    }
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network'
    },
    query: {
      fetchPolicy: 'no-cache'
    }
  }
})

export default new VueApollo({
  defaultClient: graphQL
})

export const useGraphQL = (context) => {
  const connected = ref(client?.client?.readyState === 1)

  client.on('connecting', () => { connected.value = false })
  client.on('connected', () => { connected.value = true })
  client.on('reconnecting', () => { connected.value = false })
  client.on('reconnected', () => { connected.value = true })
  client.on('disconnected', () => { connected.value = false })
  client.on('error', () => { connected.value = false })

  const login = async (email, password) => {
    try {
      const { data } = await graphQL.mutate({
        mutation: gql`mutation($email: EmailAddress, $password: String, $token: String) { login(email: $email, password: $password, token: $token) {
          _id
          token
          givenName
          familyName
          acl {
            type
            id
            startDate
            endDate
            _discipline
            gender
            event {
              ... on StbW2023Wk {
                parent {
                  _parent
                }
              }
              ... on StbLtf2024Tc {
                _parent
              }
            }
          }
        }}`,
        variables: {
          token: !email || !password ? Cookie.get('token') : undefined,
          email,
          password
        }
      })

      root.$store.commit('SET_PROFILE', data.login)

      Cookie.set('token', data?.login?.token, '12h', null, null, null, 'strict')
    } catch (e) {
      root.$store.commit('SET_PROFILE', null)

      Cookie.remove('token')

      if (email && email !== 'LOGOUT') {
        // root.$store.commit('OPEN_SNACKBAR', 'Zugangsdaten falsch')
      }
    }
  }

  const mutate = async (options) => {
    root.$store.commit('SET_LOADING', true)

    let data = null

    try {
      data = await graphQL.mutate(options)
    } catch (e) {
      console.error(e)
    }

    root.$store.commit('SET_LOADING', false)

    return data?.data
  }

  const query = async (options) => {
    root.$store.commit('SET_LOADING', true)

    let data = null

    try {
      data = await graphQL.query(options)
    } catch (e) {
      console.error(e)
    }

    root.$store.commit('SET_LOADING', false)

    return data?.data
  }

  return { connected, login, mutate, query }
}

export const updateQuery = (a, b) => (alt, neu) => {
  const i = alt[a].findIndex(old => old._id === neu.subscriptionData.data[b]._id)
  if (i !== -1) return
  return { [a]: [...alt[a], neu.subscriptionData.data[b]] }
}

export const deleteQuery = (a, b, alt, data) => {
  const id = data?.data[b]
  const i = alt.findIndex(old => old._id === id)
  if (i !== -1) {
    alt.splice(i, 1)
  }
}

export const apolloDefault = (coll, query, params = '') => {
  return {
    [`${coll}Find`]: {
      query: gql`
          query { ${coll}Find${params} { ${query} }}
          `,
      subscribeToMore: {
        document: gql`
            subscription { ${coll}Create { ${query} }}
            `,
        updateQuery: updateQuery(`${coll}Find`, `${coll}Create`)
      }
    },
    $subscribe: {
      [`${coll}Update`]: {
        query: gql`
            subscription { ${coll}Update { ${query} }}
            `
      },
      [`${coll}Delete`]: {
        query: gql`
            subscription { ${coll}Delete }
            `,
        result (id) {
          deleteQuery(`${coll}Find`, `${coll}Delete`, this[`${coll}Find`], id)
        }
      }
    }
  }
}
