<template>
  <div>
    <v-edit-dialog
      :return-value.sync="wert"
      large
      :save-text="savebutton"
      :cancel-text="cancelbutton"
      @open="open"
      @save="save"
      @close="close"
    >
      <div style="min-width:40px;">
        <v-text-field
          :value="value"
          :label="label"
          readonly
        />
      </div>
      <template #input>
        <v-autocomplete
          v-model="wert"
          :loading="loading"
          :items="items"
          :search-input.sync="search"
          label="Mannschaft suchen"
          hide-no-data
          hint="Mindestens 2 Buchstaben der Mannschaft eingeben..."
          persistent-hint
          @select="save"
        />
      </template>
    </v-edit-dialog>
  </div>
</template>

<script>
import { useGraphQL } from '@/plugins/graphql'
import gql from 'graphql-tag'

export default {
  name: 'EditDialogMannschaftsuche',

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  props: {
    value: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    gender: {
      type: String,
      required: false,
      default: null
    },
    savebutton: {
      type: String,
      required: false,
      default: 'Übernehmen'
    },
    cancelbutton: {
      type: String,
      required: false,
      default: 'Abbrechen'
    }
  },

  data: () => ({
    wert: null,
    loading: false,
    items: [],
    search: ''
  }),

  computed: {
  },

  methods: {
    open () {
      this.wert = null
    },
    close () {
      this.wert = null
    },
    save () {
      this.$emit('input', this.wert)
      this.close()
    },
    dosearch (val) {
      this.loading = true

      this.query({
        query: gql`query($name: String!, $gender: GenderType) {
          ClubTeamFind(name: $name, gender: $gender, limit: 100) {
            _id
            name
            club {
              _id name
            }
          }
        }`,
        variables: {
          name: val,
          gender: this.gender || undefined
        }
      }).then((result) => {
        this.items = result.ClubTeamFind.map(t => ({
          value: t._id,
          text: `${t.name} (${t.club.name})`
        })).sort((a, b) => a.text < b.text ? -1 : 1)

        this.loading = false
      })
    }
  },

  watch: {
    search (val) {
      if (val && val.length >= 2) this.dosearch(val)
      else this.items = []
    }
  }
}
</script>

<style>
.v-small-dialog__activator__content {
  display: block;
}

</style>
