import Vue from 'vue'
import App from './App.vue'
import router from './plugins/router'
import store from './plugins/store'
import vuetify from './plugins/vuetify'
import apolloProvider from './plugins/graphql'

import '@mdi/font/css/materialdesignicons.css'
import '@fortawesome/fontawesome-pro/css/all.css'

import './plugins/cookies'
import './plugins/fullscreen'
import './plugins/compositionAPI'
import './plugins/base'
import './plugins/openlink'
import './plugins/apexcharts'
import './plugins/signaturepad'

import './filter'
import './sass/stbm2021.css'
import './sass/stbw2023.css'
import { EventType } from '@/enum'

Vue.component('Draggable', () => import('vuedraggable'))

Vue.component('E403', () => import('./components/e403.vue'))
Vue.component('DateSelector', () => import('./components/DateSelector.vue'))
Vue.component('DateTimeSelector', () => import('./components/DateTimeSelector.vue'))
Vue.component('Confirm', () => import('./components/Confirm.vue'))

Vue.config.productionTip = false
Vue.config.devtools = true

export const displayTypes = EventType.filter(t => !t.domains || t.domains.some(d => document.location.hostname.match(new RegExp(`^${d}$`))))
export const displayType = (type) => displayTypes.some(e => e.value === type)

let cookie = []
try {
  const data = Vue.$cookies.get('visible')
  if (Array.isArray(data)) cookie = data
  else cookie = JSON.parse(Vue.$cookies.get('visible') || '[]')
} catch (e) {
  cookie = []
}
let intersection = displayTypes.map(t => t.value).filter(t => cookie.find(c => c === t))
if (intersection.length === 0) intersection = displayTypes.map(t => t.value)
Vue.$cookies.set('visible', JSON.stringify(intersection), '3650d')
store.commit('SET_VISIBLE', intersection)

export const root = new Vue({
  router,
  store,
  vuetify,
  apolloProvider,
  render: h => h(App)
}).$mount('#app')
