<template>
  <v-edit-dialog
    :return-value.sync="wert"
    large
    :save-text="savebutton"
    :cancel-text="cancelbutton"
    @open="open"
    @save="save"
    @close="close"
  >
    <div style="min-width:40px;">
      <v-text-field
        :value="value"
        :label="label"
        readonly
      >
        <template
          v-if="clearable && value"
          #append
        >
          <v-btn
            fab
            x-small
            text
            @click.stop="$emit('input', { value: null, id })"
          >
            <v-icon>far fa-trash-alt</v-icon>
          </v-btn>
        </template>
      </v-text-field>
    </div>
    <template #input>
      <v-autocomplete
        v-model="wert"
        :loading="loading"
        :items="items"
        :search-input.sync="search"
        :filter="filter"
        label="Person suchen"
        hide-no-data
        hint="Mindestens 4 Buchstaben des Namens eingeben..."
        persistent-hint
        @select="save"
      />
    </template>
  </v-edit-dialog>
</template>

<script>
import { useGraphQL } from '@/plugins/graphql'
import gql from 'graphql-tag'

export default {
  name: 'EditDialogPersonsuche',

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  props: {
    value: {
      type: String,
      required: false
    },
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    savebutton: {
      type: String,
      required: false,
      default: 'Übernehmen'
    },
    cancelbutton: {
      type: String,
      required: false,
      default: 'Abbrechen'
    },
    clearable: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    wert: null,
    tmpid: null,
    loading: false,
    items: [],
    search: ''
  }),

  computed: {
  },

  methods: {
    open () {
      this.tmpid = this.id
      this.wert = null
    },
    close () {
      this.wert = null
      this.tmpid = null
    },
    save () {
      this.$emit('input', { value: this.wert, id: this.tmpid })
      this.close()
    },
    dosearch (val) {
      this.loading = true

      this.query({
        query: gql`query($name: String!) {
          PersonFind(name: $name, limit: 100) {
            _id givenName familyName
            address { value { addressLocality } }
          }
        }`,
        variables: {
          name: val
        }
      }).then((result) => {
        this.items = result.PersonFind.map(p => ({
          value: p._id,
          text: `${p.familyName}, ${p.givenName}`,
          familyName: p.familyName,
          givenName: p.givenName
        })).sort((a, b) => a.text < b.text ? -1 : 1)
        this.loading = false
      })
    },
    filter (item, queryText, itemText) {
      const it = itemText.toLowerCase()
      const tmp = queryText.toLowerCase().split(' ')

      return tmp.reduce((acc, curr) => acc && it.indexOf(curr) !== -1, true)
    }
  },

  watch: {
    search (val) {
      if (val && val.length >= 4) this.dosearch(val)
      else this.items = []
    }
  }
}
</script>

<style>
.v-small-dialog__activator__content {
  display: block;
}

</style>
