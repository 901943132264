import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import de from 'vuetify/lib/locale/de'

import '@/sass/overrides.sass'

import VImageInput from 'vuetify-image-input/a-la-carte'

Vue.use(Vuetify)
Vue.component('VImageInput', VImageInput)

const theme = {
  primary: '#FF041D',
  secondary: '#9C27b0',
  accent: '#9C27b0',
  info: '#00CAE3'
}

export default new Vuetify({
  lang: {
    locales: { de },
    current: 'de'
  },
  icons: {
    iconfont: 'fa'
  },
  theme: {
    themes: {
      dark: theme,
      light: theme
    }
  }
})
